<template>
  <v-card elevation="0" class="pt-5">
    <div v-if="loadingStatus" class="table__spinnerWrapper">
      <app-spinner class="mt-5 mr-5"></app-spinner>
    </div>
    <div v-else>
      <v-container>
        <v-row>
          <v-col md="2" class="mr-2" v-if="returnAbility('lesson:store')">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="green"
                  class="white--text"
                >
                  <v-icon size="20">mdi-plus</v-icon>
                  {{ $t("add lesson") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  {{ $t("add lesson") }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="12">
                      <v-autocomplete
                        v-model="newLesson.lecture_id"
                        outlined
                        dense
                        :items="lectures"
                        :label="$t('lecture')"
                        item-text="title"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>
                    
                    <v-col md="12">
                      <v-text-field
                        :label="$t('date')"
                        v-model="newLesson.date"
                        outlined
                        dense
                        color="#757575"
                        type="date"
                      ></v-text-field>
                    </v-col>
                    <v-col md="12">
                      <v-text-field
                        :label="$t('title')"
                        v-model="newLesson.title"
                        outlined
                        dense
                        color="#757575"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col md="12">
                      <v-text-field
                        :label="$t('description')"
                        v-model="newLesson.description"
                        outlined
                        dense
                        color="#757575"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col md="12">
                      <v-textarea
                        v-model="newLesson.notes"
                        :label="$t('notes')"
                        outlined
                        dense
                        color="#757575"
                        type="text"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="green"
                    class="white--text"
                    @click="submitNewLesson"
                    :loading="submitBtnLoading"
                    :disabled="disableSubmitBtn"
                    >{{ $t("add") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <template v-for="(info, key, index) of responsibility">
            <v-col md="4" :key="index" v-if="info.value">
              <h3 class="ml-5">
                {{ $t(info.desc) }} :
                <span style="font-weight: 100">{{ info.value }}</span>
              </h3>
            </v-col>
          </template>
        </v-row>
      </v-container>
      <app-base-table
        :tableOptions="tableOptions"
        @re-fetch-data="fetchData"
        serverRoute="/lesson"
        @re-fetch-paginated-data="fetchData($event)"
        :enableDelete="displayDeleteIcon"
        :flaggedItems="flaggedItemsData"
      >
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon
            color="green"
            @click="$router.push(`/view-teacher-lesson/${row.id}`)"
            >mdi-eye</v-icon
          >
        
          <app-edit-lesson-modal
            @recordUpdated="fetchData"
            :lesson="row"
          ></app-edit-lesson-modal>
        </template>
      </app-base-table>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import editLessonModal from "./editLessonModal.vue";
export default {
  components: {
    appEditLessonModal: editLessonModal,
  },

  data() {
    return {
      submitBtnLoading: false,
      newLesson: {
        date: null,
        title: "",
        description: "",
        notes: "",
        responsibility_id: this.$route.params.id,
        lecture_id: null,
        // subject_id: this.responsibility.subject.id,
      },
    };
  },
  computed: {
    ...mapGetters({
      responsibility: "responsibilities/getResponsibiltyData",
      loadingStatus: "responsibilities/getLoadingStatus",
      getTableLoadingStatus: "responsibilities/getTableLoading",
      // finally: "responsibilities/getFinallyStatus",
      tableOptions: "lessons/getTableOptions",
      lectures: "subjects/getLectures",
      //   loadingStatus: "lessons/getLoadingStatus",
    }),
    disableTabs() {
      if (this.getTableLoadingStatus) {
        return true;
      } else {
        return false;
      }
    },
    disableSubmitBtn() {
      if (
        !this.newLesson.date ||
        !this.newLesson.title ||
        !this.newLesson.lecture_id ||
        !this.newLesson.description ||
        !this.newLesson.notes
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions({
      fetchResponsibility: "responsibilities/fetchResponsibility",
      fetchLessons: "lessons/fetchLessons",
      fetchLectures: "subjects/fetchLectures",
    }),

    fetchData(page) {
      this.fetchResponsibility(this.$route.params.id);
      this.fetchLessons({
        params: {
          page,
          responsibility_id: this.$route.params.id,
        },
      });

      // console.log(this.responsibility.subject.id);
    },
    async submitNewLesson() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/lesson", this.newLesson);
        if(response.status==200){
          this.$Notifications(
          this.$t("add success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        }
        this.fetchData();
        this.dialog = false;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  watch: {
    loadingStatus: {
      handler() {
     
        if ( this.loadingStatus == false) {
          this.fetchLectures({
            params: {
              subject_id: this.responsibility.subject.id,
            },
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.returnAbility("teacher:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>
<style scoped>
.titlee {
  font-weight: 700;
  color: rgb(0, 0, 0);
  margin: 0.5vw;
}
.text {
  border: 1px solid rgb(182, 179, 179);
  width: 100%;
  padding: 0.5vw;
  border-radius: 0.3vw;
  color: gray;
  font-size: 1vw;
  box-shadow: 1px 2px #dad9d9;
}
</style>
